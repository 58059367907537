<template>
  <div class="shelve_goods_management">
    <el-form class="zwx-form" key="trainingCourseForm" ref="trainingCourseForm" :model="trainingCourseForm" label-position="right" @submit.native.prevent>
      <el-form-item label="机构地区：" label-width="100px">
        <zwx-select-area class="cascader-area-biz-zone" ref="bizZoneArea" @change="bizZoneChange" @loadOver="areaIdLoadOver()" :zoneCode12From="zoneCode12From" zoneTypeMax="0" zoneTypeMin="4" :showFullName="false" :clearable="false" />
      </el-form-item>
      <el-form-item label="培训机构：" label-width="100px">
        <!-- <el-select v-model="trainingCourseForm.agencyUid" placeholder="请选择" class="zwx-select zwx-select-student" clearable>
          <el-option v-for="item in agencyInfoList" :key="item.rid" :label="item.unitName" :value="item.unitUid"></el-option>
        </el-select> -->
        <el-input class="zwx-input" v-model.trim="trainingCourseForm.unitName" style="width: 160px !important" placeholder="" clearable maxlength="50" />
      </el-form-item>
      <el-form-item label="培训班名称：" label-width="100px">
        <el-input class="zwx-input" v-model.trim="trainingCourseForm.trainingActivityName" style="width: 160px !important" placeholder="" clearable maxlength="50" />
      </el-form-item>
      <!-- <el-form-item label="考试类型：" label-width="100px">
        <el-select v-model="trainingCourseForm.examType" placeholder="请选择" class="zwx-select">
          <el-option label="不限" :value="''"></el-option>
          <el-option label="线上考试" :value="1"></el-option>
          <el-option label="线下考试" :value="2"></el-option>
        </el-select>
      </el-form-item> -->
      <div>
        <el-form-item label="报名状态：" label-width="100px">
          <el-select v-model="trainingCourseForm.registStatus" placeholder="请选择" class="zwx-select" popper-class="zwx-select-popper">
            <el-option label="不限" :value="''"></el-option>
            <el-option label="未开始" :value="0"></el-option>
            <el-option label="进行中" :value="1"></el-option>
            <el-option label="已截止" :value="-1"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="培训班状态：" label-width="100px">
          <el-select v-model="trainingCourseForm.ifEnable" placeholder="请选择" class="zwx-select" @change="ifEnableSet">
            <el-option label="不限" :value="null"></el-option>
            <el-option label="启用" :value="true"></el-option>
            <el-option label="停用" :value="false"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="线下培训时间：" label-width="100px">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width: 160px !important" v-model="trainingCourseForm.offLineStartTime" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="$validate.noBigDate($data['trainingCourseForm'], 'offLineEndTime', true)" placeholder="开始日期" />
          -
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width: 160px !important" v-model="trainingCourseForm.offLineEndTime" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="$validate.noSmallDate($data['trainingCourseForm'], 'offLineStartTime', true)" placeholder="结束日期" />
        </el-form-item>
      </div>
    </el-form>
    <div class="independent-button-row">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="queryTrainingCourse(1)">查询</el-button>
    </div>
    <!-- 列表展示部分 -->
    <el-table class="zwx-table" row-key="rid" :data="tableList" border stripe tooltip-effect="light">
      <el-table-column prop="busZoneName" label="机构地区" width="170" header-align="center" align="left">
        <template slot-scope="{ row }">
          {{ row.busZoneName || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="unitName" label="培训机构名称" min-width="220" header-align="center" align="left">
        <template slot-scope="{ row }">
          {{ row.unitName || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="trainingActivityName" label="培训班名称" min-width="220" header-align="center" align="left">
        <template slot-scope="{ row }">
          {{ row.trainingActivityName || '-' }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="examType" label="考试类型" width="90" header-align="center" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.examType == 1">线上考试</span>
          <span v-if="scope.row.examType == 2">线下考试</span>
        </template>
      </el-table-column> -->
      <el-table-column prop="price" label="报名时间" width="340" header-align="center" align="center">
        <template slot-scope="scope">
          <span>
            {{ scope.row.registStartTime }}
          </span>
          至
          <span>
            {{ scope.row.registEndTime }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="线下培训时间" width="340" header-align="center" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.offLineStartTime">
            <span>
              {{ scope.row.offLineStartTime }}
            </span>
            至
            <span>
              {{ scope.row.offLineEndTime }}
            </span>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column prop="registStatusStr" label="报名状态" width="120" header-align="center" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.registStatus == -1">已截止</span>
          <span v-else-if="row.registStatus == 1">进行中</span>
          <span v-else>未开始</span>
        </template>

        <template slot-scope="scope">
          <span class="zwx-circle-text zwx-circle-text:before" :class="getClass(scope.row.registStatus)">
            {{ getStatus(scope.row.registStatus) }}
          </span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="ifEnable" label="培训班状态" width="100" header-align="center" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.ifEnable">启用</span>
          <span v-else>停用</span>
        </template>
      </el-table-column> -->
      <el-table-column prop="traineeListCount" label="学员人数" min-width="100" header-align="center" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.traineeListCount != undefined">{{ scope.row.traineeListCount }}</span>
          <span v-else>0</span>
        </template>
      </el-table-column>
      <!-- <el-table-column fixed="right" label="操作" width="100" header-align="left" align="left">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" @click="detail(scope.row)">查看</el-button>
        </template>
      </el-table-column> -->
    </el-table>

    <!-- 分页器部分 -->
    <base-pagination :parentPage="trainingCourseForm.currentPage" :pageSize="trainingCourseForm.pageSize" :total="trainingCourseForm.total" @currentChange="queryTrainingCourse" />
    <!-- 签到码 -->
    <el-dialog title="签到码" :visible.sync="signInQrCodeVisible" class="bindQrCodeDialog">
      <img style="width: 260px; margin: 10px 40px 0px" :src="file + bindQrCodePath" />
      <div style="display: flex; justify-content: right; padding: 0 20px 10px">
        <el-button @click="downloadSignInQrCode">下载</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'TrainingManagement',
  data() {
    return {
      api: this.$store.state.api,
      file: this.$store.state.file,
      users: this.$store.state.users,
      trainingCourseForm: {
        agencyUid: '',
        busZoneCode: '',
        agencyUnitname: '',
        trainingActivityName: '', //培训班名称
        examType: '', //考试状态
        registStatus: '', //线上报名状态
        ifEnable: true, //培训班状态
        offLineStartTime: '', //线下培训开始时间
        offLineEndTime: '', //线下培训结束时间
        currentPage: 1,
        pageSize: 18,
        total: 0,
      },
      zoneCode12From: '',
      tableList: [],
      signInQrCodeVisible: false,
      bindQrCodePath: '',
      bindQrCodeName: '',
      agencyInfoList: [],
    }
  },
  activated() {
    if (this.$route.params.fresh) this.queryTrainingCourse(1)
  },
  mounted() {
    //查询所有培训机构
    this.queryAllAgencyInfo()
    // 赋值当前用户所属地区
    let config = window.top.vm.$store.state.config

    this.zoneCode12From = config.empower //'320200000000'
    this.$nextTick(() => {
      this.$refs.bizZoneArea.initSearch(this.zoneCode12From)
      this.$forceUpdate()
    })
  },
  methods: {
    getClass(status) {
      let val = ''
      switch (status) {
        case -1:
          val = 'zwx-circle-finish zwx-circle-finish:before'
          break
        case 1:
          val = 'zwx-circle-normal zwx-circle-normal:before'
          break
        default:
          val = 'zwx-circle-underway zwx-circle-underway:before'
          break
      }
      return val
    },
    getStatus(status) {
      let val = ''
      switch (status) {
        case -1:
          val = '已截止'
          break
        case 1:
          val = '进行中'
          break
        default:
          val = '未开始'
          break
      }
      return val
    },
    areaIdLoadOver() {
      this.queryTrainingCourse(1)
    },
    //查询所有的培训机构
    queryAllAgencyInfo() {
      this.$system.get(
        this.api + '/training/platform/getTdTrainingAgencyListAll-1',
        null,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.agencyInfoList = data.tdTrainingAgencyList
          }
        },
        () => {
          this.$system.error({ title: '错误', message: data.mess })
        }
      )
    },
    bizZoneChange() {
      this.trainingCourseForm.busZoneCode = this.$refs.bizZoneArea.getChooseArea().zoneCode12
    },
    traineeTypeChange(data) {
      this.trendAnalysisForm.trainingType = ''
      if (data) {
        this.trendAnalysisFormExp.trainingTypeChangeOptions = this.manyListFul(this.trendAnalysisFormExp.trainingTypeOptions, data)
      } else {
        this.trendAnalysisFormExp.trainingTypeChangeOptions = []
      }
    },
    queryTrainingCourse(currentPage) {
      this.trainingCourseForm.currentPage = currentPage
      this.trainingCourseForm.busZoneCode = this.$refs.bizZoneArea.getChooseArea().zoneCode12
      let data = {
        ...this.trainingCourseForm,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/training/platform/getAllTrainingActivityMainList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.tableList = data.trainingActivityMainList
            this.trainingCourseForm.pageSize = data.pageSize
            this.trainingCourseForm.total = data.trainingActivityMainCount
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.error({ title: '错误', message: data.mess })
        }
      )
    },
    ifEnableSet(data) {
      if (data == 'null') {
        this.trainingCourseForm.ifEnable = null
      }
    },
    add() {
      this.$router.push({ name: 'TrainingManagementEdit', params: { type: 'add' } })
    },
    edit(row) {
      this.$router.push({ name: 'TrainingManagementEdit', params: { row: row, type: 'edit' } })
    },
    // 删除
    deleteList(row) {
      let data = {
        rid: row.rid,
      }
      this.$system.msgbox('', '提示', '是否确认删除？', '确定', '取消', () => {
        this.$system.postJson(
          this.api + '/training/platform/deleteTdTrainingActivityMain-1',
          data,
          true,
          true,
          data => {
            this.$emit('loading', false)
            if (data.type === '00') {
              this.$system.notify('成功', '删除成功', 'success')
              this.queryTrainingCourse(this.trainingCourseForm.currentPage)
            } else if (data.type === '99') {
              this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
            } else {
              this.$notify.error({ title: '错误', message: data.mess })
            }
          },
          () => {
            this.$emit('loading', false)
            this.$notify.error({ title: '错误', message: '网络连接失败' })
          }
        )
      })
    },
    // 查看详情
    detail(row) {
      this.$router.push({ name: 'MedicineTrainingManagementDetail', params: { row: row, type: '2' } })
    },
    // 启用停用
    enableChange(row) {
      let data = {
        rid: row.rid,
        ifEnable: !row.ifEnable,
      }
      this.$emit('loading', true)
      if (row.ifEnable) {
        this.$system.msgbox('', '提示', '确认是否停用？', '确定', '取消', () => {
          this.$system.get(
            this.api + '/training/platform/updateTdTrainingActivityMainByEnable-1',
            data,
            true,
            true,
            data => {
              this.$emit('loading', false)
              if (data.type === '00') {
                this.queryTrainingCourse(this.trainingCourseForm.currentPage)
              }
            },
            data => {
              this.$emit('loading', false)
              this.$system.error({ title: '错误', message: data.mess })
            }
          )
        })
      } else {
        this.$system.get(
          this.api + '/training/platform/updateTdTrainingActivityMainByEnable-1',
          data,
          true,
          true,
          data => {
            this.$emit('loading', false)
            if (data.type === '00') {
              this.queryTrainingCourse(this.trainingCourseForm.currentPage)
            }
          },
          () => {
            this.$emit('loading', false)
            this.$system.error({ title: '错误', message: data.mess })
          }
        )
      }
    },
    // signInQrCodeShow
    signInQrCodeShow(row) {
      let data = {
        uuid: row.uuid,
        sceneType: 2,
      }
      this.$system.postJson(
        this.api + '/training/platform/trainingActivityMainUploadQrCode-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.signInQrCodeVisible = true
            this.bindQrCodePath = data.qrCodePath
            this.bindQrCodeName = row.trainingActivityName + '_签到二维码'
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        () => {
          this.$emit('loading', false)
          this.$notify.error({ title: '错误', message: '网络连接失败' })
        }
      )
      // this.signInQrCodeNow = row.signInQrCode
      // this.signInQrCodeVisible = true
    },
    downloadSignInQrCode() {
      if (this.$zwxBase.verifyIsNotBlank(this.bindQrCodePath)) {
        this.$system.downloadUrlFile(this.bindQrCodeName, this.$store.state.api + this.bindQrCodePath)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.bindQrCodeDialog /deep/.el-dialog {
  width: fit-content;
  min-width: auto !important;
}

/deep/.agency-css {
  .el-input {
    width: 334px !important;
  }
  .el-input__inner {
    width: 334px !important;
  }
}
</style>
