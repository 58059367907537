<template>
  <div class="three-simultaneous-edit-page">
    <el-form class="zwx-form edit-form" key="msEditForm" :model="msEditForm" :rules="msEditFormRules" ref="msEditForm" label-position="right" @submit.native.prevent>
      <base-headline title="基本信息" class="no-top" />
      <div style="border-bottom: 1px solid #D5D9E0">
        <div class="edit-row">
          <el-form-item label-width="85px" label="项目名称：" prop="projectName">
            <el-input style="width:300px !important" class="zwx-input" placeholder="请输入" v-model.trim="msEditForm.projectName" maxlength="200" clearable />
          </el-form-item>
          <el-form-item label-width="82px" label="项目地址：">
            <el-input class="zwx-input " placeholder="请输入" style="width: 442px !important;" v-model.trim="msEditForm.projectAddr" maxlength="200" clearable />
          </el-form-item>
          <el-form-item label-width="82px" label="项目性质：" prop="projectNature">
            <el-radio-group class="zwx-radio-group" v-model="msEditForm.projectNature" style="min-width: 80px !important;">
              <el-radio class="zwx-radio" label="40120001">新建</el-radio>
              <el-radio class="zwx-radio" label="40120002">改建</el-radio>
              <el-radio class="zwx-radio" label="40120003">扩建</el-radio>
              <el-radio class="zwx-radio" label="40120004">技术改造</el-radio>
              <el-radio class="zwx-radio" label="40120005">技术引进</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="110px" label="批复单位类型：" prop="approvedUnitType">
            <cascader-single class="zwx-cascader-single-common" ref="unitTypeCascader" placeholder="请选择" v-model="msEditForm.approvedUnitType" :simpleCodeList="msEditFormExp.unitTypeSimpleCodeList" expandTrigger="hover" codeTypeCode="1003"></cascader-single>
          </el-form-item>
          <el-form-item label-width="110px" label="批复单位级别：" prop="approvedUnitLevel">
            <el-select class="zwx-select" v-model="msEditForm.approvedUnitLevel" placeholder="请选择" clearable>
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label-width="70px" label="批复日期：">
            <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" style="width: 200px !important;" v-model="msEditForm.approvedDate" type="date" placeholder="请选择" :picker-options="$validate.noBigDate($data['msEditForm'], 'completionDate')" />
          </el-form-item>
          <el-form-item label-width="82px" label="竣工日期：">
            <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" style="width: 200px !important;" v-model="msEditForm.completionDate" type="date" placeholder="请选择" :picker-options="$validate.noSmallDate($data['msEditForm'], 'approvedDate')" />
          </el-form-item>
          <el-form-item label-width="160px" label="职业病危害风险分类：" prop="hazardRisk">
            <el-radio-group class="zwx-radio-group" v-model="msEditForm.hazardRisk" style="min-width: 80px !important;">
              <el-radio class="zwx-radio" :label="1">严重</el-radio>
              <el-radio class="zwx-radio" :label="2">一般</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div>
          <el-form-item label-width="100px" label="项目内容：" prop="projectContent">
            <el-input class="zwx-textarea" type="textarea" v-model.trim="msEditForm.projectContent" style="width: 1435px !important;" :autosize="{ minRows: 4 }" maxlength="200" resize="none" placeholder="请输入" clearable />
          </el-form-item>
        </div>
      </div>
      <base-headline title="报告信息" class="no-top no-bottom">
        <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addReportList">添加</el-button>
      </base-headline>
      <el-table class="zwx-table" style="width: 100%" row-key="rid" key="reportListTable" :data="msEditForm.reportList" stripe border tooltip-effect="light">
        <el-table-column prop="projectProcess" label="类型" width="120" header-align="center" align="left" :formatter="projectProcessFormatter"></el-table-column>
        <el-table-column prop="responsibleUnitName" label="报告/专篇编制机构名称" width="300" header-align="center" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column prop="reportCode" label="报告专篇编号" width="200" header-align="center" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column prop="approvalDepartment" label="报审部门" width="200" header-align="center" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column prop="inspectionDate" label="评审时间" width="200" header-align="center" align="center" :formatter="dateFormatter"></el-table-column>
        <el-table-column label="报告附件" header-align="center" align="center" width="100">
          <template slot-scope="scope">
            <template v-if="$zwxBase.verifyIsBlank(scope.row.fileList)">
              <div class="zwx-folder-content">
                <i class="filled chis-icon-filled-file-folder" style="color: #1990ff;"></i>
                <span class="zwx-folder-content-num">0</span>
              </div>
            </template>
            <template v-else>
              <el-dropdown placement="bottom" trigger="click">
                <div class="zwx-folder-content">
                  <i class="filled chis-icon-filled-file-folder" style="color: #1990ff;"></i>
                  <span class="zwx-folder-content-num">{{ scope.row.fileList.length }}</span>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item, index) in scope.row.fileList" :key="index" @click.native="openFilePreview1(scope.row.fileList, index)">
                    <div>{{ item.fileName }}</div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="操作" header-align="center" align="left" fixed="right" min-width="100">
          <template slot-scope="scope">
            <el-button class="zwx-button zwx-button-text-26" type="text" @click="editReport(scope.row, scope.$index)">编辑</el-button>
            <el-button class="zwx-button zwx-button-text-26 zwx-button-danger" type="text" @click="deleteReport(scope.row, scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <fixed-footer>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="saveThreeSimultaneous" :disabled="saveButtonDisabled">保存</el-button>
    </fixed-footer>
    <!--报告信息弹框-->
    <base-dialog class="three-simultaneous-report" ref="reportDialogFormRef" :title="reportDialogForm.title" @determine="confirmReportDialogForm()" @cancel="cancelreportDialogForm" @close="closereportDialogForm" :pagination="false" :if-show-close="true">
      <el-form class="zwx-form edit-form " style="padding: 0 !important" key="reportFormRef" ref="reportFormRef" :model="reportDialogForm" label-position="right" :rules="reportRules" @submit.native.prevent>
        <div class="edit-row">
          <el-form-item label-width="53px" label="类型：" prop="projectProcess">
            <el-radio-group style="min-width: 100px !important" class="zwx-radio-group" v-model="reportDialogForm.projectProcess">
              <el-radio class="zwx-radio" :label="2">预评价</el-radio>
              <el-radio class="zwx-radio" :label="3">防护设计专篇</el-radio>
              <el-radio class="zwx-radio" :label="4">控制效果评价</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="170px" label="报告/专篇编制机构名称：" prop="responsibleUnitName">
            <el-input class="zwx-input" style="width:320px !important" v-model.trim="reportDialogForm.responsibleUnitName" placeholder="" maxlength="200" clearable />
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="115px" label="报告/专篇编号：" prop="reportCode">
            <el-input class="zwx-input" style="width:374px !important" v-model.trim="reportDialogForm.reportCode" placeholder="" maxlength="50" clearable />
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="81px" label="报审部门：" prop="approvalDepartment">
            <el-input class="zwx-input" style="width:147px !important" v-model.trim="reportDialogForm.approvalDepartment" placeholder="" maxlength="200" clearable />
          </el-form-item>
          <el-form-item label-width="81px" label="评审日期：" prop="inspectionDate">
            <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width: 160px !important;" v-model="reportDialogForm.inspectionDate" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="" :picker-options="pickerOptions" />
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="81px" label="报告附件：" prop="annexList">
            <div style="display:flex;">
              <file-upload ref="msPositive" @showFileList="clickPositive" @success="clickPositiveSuccess" @error="clickError()" accept=".pdf,.PDF" :size="50 * 1024 * 1024" sizeInfo="50M">
                <div style="text-align: left" v-if="$zwxBase.verifyIsBlank(reportDialogForm.annexList) || reportDialogForm.annexList.length < 1">
                  <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-upload2">上传</el-button>
                </div>
              </file-upload>
              <div style="display: inline-block" class="show-file" v-if="reportDialogForm.annexList.length > 0">
                <div style="display: flex;align-items: center;" v-for="(item, index) in reportDialogForm.annexList" :key="item.fileName" @mouseenter="mouseenter(index)" @mouseleave="mouseleave(index)">
                  <div class="file-name-hidden">
                    <a style="color:#194DFF !important;margin-left:0px;cursor:pointer;" @click="openFilePreview1(reportDialogForm.annexList, index)">
                      <i :class="item.fileIconShow" style="color:#316CF5!important"></i>
                      <span :title="item.fileName">{{ item.fileName }}</span>
                    </a>
                  </div>
                  <div>
                    <span class="el-upload-list__item check" :style="item.check ? 'margin-left:18px;display:none;' : 'margin-left:18px;cursor:pointer'"><i class="el-icon-upload-success el-icon-circle-check"></i></span>
                    <span class="check" :style="item.check ? 'margin-left:18px;cursor:pointer' : 'margin-left:18px;display:none;'"><i class="el-icon-close" @click="deletionPositive(index)"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
        </div>
      </el-form>
    </base-dialog>
  </div>
</template>

<script>
export default {
  name: 'ThreeSimultaneousEditPage',
  data() {
    return {
      api: this.$store.state.api,
      type: this.$route.params.type,
      users: this.$store.state.users,
      //employerUuid:this.$store.params.employerUuid,
      msEditForm: {
        employerUuid: '',
        rid: '',
        projectName: '',
        projectAddr: '',
        projectNature: '',
        approvedUnitType: '',
        approvedUnitLevel: '',
        approvedDate: '',
        completionDate: '',
        hazardRisk: '',
        projectContent: '',
        reportList: [],
      },
      options: [
        {
          value: 1,
          label: '国家级',
        },
        {
          value: 2,
          label: '省级',
        },
        {
          value: 3,
          label: '地市级',
        },
        {
          value: 4,
          label: '县（区）级',
        },
      ],
      msEditFormExp: {
        unitTypeSimpleCodeList: [], // 批复单位类型下拉
        msEditFormJson: '',
      },
      msEditFormRules: {
        projectName: [{ required: true, message: '请输入项目名称', trigger: ['blur'] }],
        projectNature: [{ required: true, message: '请选择项目性质', trigger: 'change' }],
        hazardRisk: [{ required: true, message: '请选择职业病危害风险分类', trigger: 'change' }],
        projectContent: [{ required: true, message: '请输入项目内容', trigger: ['blur'] }],
      },
      reportDialogForm: {
        title: '',
        rowIndex: '',
        rid: '',
        mainId: '',
        projectProcess: '',
        approvalDepartment: '',
        responsibleUnitName: '',
        inspectionDate: '',
        reportCode: '',
        reportFileName: '',
        reportFilePath: '',
        type: '',
        annexList: [],
      },
      reportRules: {
        projectProcess: [{ required: true, message: '请选择类型', trigger: 'change' }],
        responsibleUnitName: [{ required: true, message: '请输入机构名称', trigger: ['blur'] }],
        reportCode: [{ required: true, message: '请输入报告编号', trigger: ['blur'] }],
        inspectionDate: [{ required: true, message: '请选择评审日期', trigger: ['change'] }],
        annexList: [{ required: true, message: '请上传报告附件', trigger: ['change', 'blur'] }],
      },
      pickerOptions: {
        disabledDate: time => {
          var that = this
          var approvedDate = that.msEditForm.approvedDate
          var completionDate = that.msEditForm.completionDate
          if (that.$zwxBase.verifyIsNotBlank(approvedDate) && that.$zwxBase.verifyIsNotBlank(completionDate)) {
            return time.getTime() < new Date(that.msEditForm.approvedDate).getTime() || time.getTime() > new Date(that.msEditForm.completionDate).getTime()
          }
          if (that.$zwxBase.verifyIsBlank(approvedDate) || that.$zwxBase.verifyIsBlank(completionDate)) {
            return time.getTime() > Date.now() - 8.64e7
          }
        },
      },
    }
  },
  activated() { },
  computed: {
    saveButtonDisabled() {
      if (JSON.stringify(this.msEditForm) !== this.msEditFormExp.msEditFormJson) {
        return false
      } else {
        return true
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    to.params.fresh = true
    // 路由返回跳转确认
    let json = ''
    json = JSON.stringify(this.msEditForm)
    if (this.msEditFormExp.msEditFormJson !== json) {
      this.$confirm('数据还未保存，确定取消操作吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          next()
        })
        .catch(() => { })
    } else {
      next()
    }
  },
  created() {
    this.msEditForm.employerUuid = this.$route.params.employerUuid
  },
  mounted() {
    this.$SimpleCodeTools.getSimpleCodeListByCodeTypeCodes('4014', data => {
      this.msEditFormExp.unitTypeSimpleCodeList = data['4014']
      if (this.$zwxBase.verifyIsNotBlank(this.$route.params.rid)) {
        this.$emit('breadcrumb', '编辑', true)
        // 查询三同时基本信息
        this.getDetail(this.$route.params.rid)
      } else {
        this.$emit('breadcrumb', '添加', true)
        this.msEditFormExp.msEditFormJson = JSON.stringify(this.msEditForm)
      }
    })
  },
  methods: {
    /**
     * 查询三同时基本信息
     */
    getDetail(rid) {
      let data = {
        rid: rid,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/oh/declaration/getThreeSimultaneousRecord-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.msEditForm.rid = data.threeSimultaneousRecord.rid
            this.msEditForm.projectName = data.threeSimultaneousRecord.projectName
            this.msEditForm.projectAddr = data.threeSimultaneousRecord.projectAddr
            this.msEditForm.projectNature = data.threeSimultaneousRecord.projectNature
            this.msEditForm.approvedUnitType = data.threeSimultaneousRecord.approvedUnitType
            this.msEditForm.approvedUnitLevel = data.threeSimultaneousRecord.approvedUnitLevel
            this.msEditForm.approvedDate = data.threeSimultaneousRecord.approvedDate
            this.msEditForm.completionDate = data.threeSimultaneousRecord.completionDate
            this.msEditForm.hazardRisk = data.threeSimultaneousRecord.hazardRisk
            this.msEditForm.projectContent = data.threeSimultaneousRecord.projectContent
            if (this.$zwxBase.verifyIsNotBlank(data.threeSimultaneousRecord.threeSimultaneousReportExpList)) {
              var reportList = data.threeSimultaneousRecord.threeSimultaneousReportExpList
              for (let index = 0; index < reportList.length; index++) {
                const item = reportList[index]
                if (this.$zwxBase.verifyIsNotBlank(item.reportFileName)) {
                  var fileList = []
                  fileList.push({ fileName: item.reportFileName, filePath: item.reportFilePath })
                  item.fileList = fileList
                }
              }
            }
            this.msEditForm.reportList = data.threeSimultaneousRecord.threeSimultaneousReportExpList
            this.msEditFormExp.msEditFormJson = JSON.stringify(this.msEditForm)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 打开添加 报告信息弹框
     */
    addReportList() {
      this.$refs.reportDialogFormRef.show(true)
      this.reportDialogForm.title = '添加报告信息'
      this.reportDialogForm.type = 'add'
    },
    /**
     * 取消弹框
     */
    cancelreportDialogForm() {
      this.$refs.reportDialogFormRef.show(false)
      this.closereportDialogForm()
    },
    /**
     * 关闭弹框 回调
     */
    closereportDialogForm() {
      this.reportDialogForm.title = ''
      this.reportDialogForm.rid = ''
      this.reportDialogForm.mainId = ''
      this.reportDialogForm.projectProcess = ''
      this.reportDialogForm.responsibleUnitName = ''
      this.reportDialogForm.approvalDepartment = ''
      this.reportDialogForm.responsibleUnitName = ''
      this.reportDialogForm.inspectionDate = ''
      this.reportDialogForm.reportCode = ''
      this.reportDialogForm.reportFileName = ''
      this.reportDialogForm.reportFilePath = ''
      this.reportDialogForm.annexList = []
    },
    /**
     * 打开 编辑 报告信息弹框
     */
    editReport(row, index) {
      this.$refs.reportDialogFormRef.show(true)
      this.reportDialogForm.title = '编辑报告信息'
      this.reportDialogForm.type = 'edit'
      this.reportDialogForm.rid = row.rid
      this.reportDialogForm.rowIndex = index
      this.reportDialogForm.mainId = this.$route.params.rid
      this.reportDialogForm.projectProcess = row.projectProcess
      this.reportDialogForm.approvalDepartment = row.approvalDepartment
      this.reportDialogForm.responsibleUnitName = row.responsibleUnitName
      this.reportDialogForm.inspectionDate = row.inspectionDate
      this.reportDialogForm.reportCode = row.reportCode
      this.reportDialogForm.reportFileName = row.fileList[0].fileName
      this.reportDialogForm.reportFilePath = row.fileList[0].filePath
      if (this.$zwxBase.verifyIsNotBlank(this.reportDialogForm.reportFileName)) {
        var list = []
        list.push({ fileName: this.reportDialogForm.reportFileName, filePath: this.reportDialogForm.reportFilePath, fileIconShow: 'colorful chis-icon-colorful-pdf', check: true })
        this.reportDialogForm.annexList = list
      }
    },
    /**
     * 删除报告信息
     */
    deleteReport(row, index) {
      // 页面删除 不走数据库
      this.msEditForm.reportList.splice(index, 1)
    },
    /**
     * 报告弹框 点击确定
     */
    confirmReportDialogForm() {
      this.$refs.reportFormRef.validate(valid => {
        if (valid) {
          this.generateFilesUid()
        }
      })
    },
    /**
     * 保存
     */
    saveThreeSimultaneous() {
      this.$refs.msEditForm.validate(valid => {
        if (valid) {
          // 报告信息list
          var list = []
          this.msEditForm.reportList.forEach(item => {
            list.push({
              rid: '',
              projectProcess: item.projectProcess,
              approvalDepartment: item.approvalDepartment,
              responsibleUnitName: item.responsibleUnitName,
              inspectionDate: item.inspectionDate,
              reportCode: item.reportCode,
              reportFileName: item.fileList[0].fileName,
              reportFilePath: item.fileList[0].filePath,
            })
          })
          let data = {
            rid: this.msEditForm.rid,
            projectName: this.msEditForm.projectName,
            projectAddr: this.msEditForm.projectAddr,
            projectNature: this.msEditForm.projectNature,
            approvedUnitType: this.msEditForm.approvedUnitType,
            approvedUnitLevel: this.msEditForm.approvedUnitLevel,
            approvedDate: this.msEditForm.approvedDate,
            completionDate: this.msEditForm.completionDate,
            hazardRisk: this.msEditForm.hazardRisk,
            projectContent: this.msEditForm.projectContent,
            threeSimultaneousReportExpList: list,
            employerUuid: this.msEditForm.employerUuid,
          }
          this.$emit('loading', true)
          this.$system.postJson(
            this.api + '/oh/declaration/addOrModifyThreeSimultaneousRecord-1',
            data,
            true,
            true,
            data => {
              this.$emit('loading', false)
              if (data.type === '00') {
                this.msEditFormExp.msEditFormJson = JSON.stringify(this.msEditForm)
                this.$system.notify('成功', data.mess, 'success')
                this.$router.push({
                  name: 'ThreeSimultaneousMainPage',
                  params: { fresh: true },
                })
              } else if (data.type === '99') {
                this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
              } else {
                this.$system.notify('错误', data.mess, 'error')
              }
            },
            data => {
              this.$emit('loading', false)
              this.$system.notify('错误', data.mess, 'error')
            }
          )
        }
      })
    },
    clickPositive(showList) {
      showList.forEach(item => {
        let flag = this.reportDialogForm.annexList.some(e => e.fileName == item)
        if (flag) {
          this.$refs.msPositive.deleteIndex(showList.length - 1)
          return
        }
        this.reportDialogForm.annexList.push({
          fileName: item,
          fileIconShow: this.dealfilePrefix(item),
          check: false,
        })
      })
      if (this.reportDialogForm.annexList != null && this.reportDialogForm.annexList != undefined && this.reportDialogForm.annexList.length > 0) {
        this.$refs.reportFormRef.clearValidate('annexList')
      }
    },

    dealfilePrefix(file) {
      let prefix = file.substring(file.indexOf('.'))
      let fileIconShow = ''
      if (prefix === '.pdf' || prefix === '.PDF') {
        fileIconShow = 'colorful chis-icon-colorful-pdf'
      } else if (prefix === '.doc' || prefix === '.docx' || prefix === '.DOC' || prefix === '.DOCX') {
        fileIconShow = 'colorful chis-icon-colorful-word'
      } else if (prefix === '.png' || prefix === '.jpeg' || prefix === '.jpg' || prefix === '.JPG' || prefix === '.JPEG' || prefix === '.PNG') {
        fileIconShow = 'colorful chis-icon-colorful-jpg'
      }
      return fileIconShow
    },
    // 上传检测报告 文件的UID
    generateFilesUid() {
      this.$emit('loading', true)
      let data = {
        employerUuid: this.msEditForm.employerUuid,
        folder: '/three_simultaneous',
      }
      this.$system.get(this.api + '/systematic/tools/getAnnexUploadUid-1', data, true, true, this.generateFilesUidSuccess, {})
    },
    generateFilesUidSuccess(data) {
      if (data.type === '00') {
        this.$refs.msPositive.submit(data.annexUploadUid)
      } else {
        this.$emit('loading', false)
        this.$system.notify('错误', data.mess, 'error')
      }
    },
    clickPositiveSuccess(fileList) {
      if (this.$zwxBase.verifyIsNotBlank(this.reportDialogForm.annexList) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.reportDialogForm.annexList) {
          for (let j in fileList) {
            if (this.reportDialogForm.annexList[i].fileName === fileList[j].fileName) {
              this.reportDialogForm.annexList[i].filePath = fileList[j].filePath
            }
          }
        }
      }
      this.saveReportDialog()
    },
    /**
     * 保存
     */
    saveReportDialog() {
      this.$emit('loading', false)
      if (this.reportDialogForm.type === 'add') {
        // 将弹框内容 处理
        var projectProcess = this.reportDialogForm.projectProcess // 类型
        var responsibleUnitName = this.reportDialogForm.responsibleUnitName //机构名称
        var reportCode = this.reportDialogForm.reportCode //编号
        var approvalDepartment = this.reportDialogForm.approvalDepartment //报审部门
        var inspectionDate = this.reportDialogForm.inspectionDate //评审日期
        var fileName = this.reportDialogForm.annexList[0].fileName
        var filePath = this.reportDialogForm.annexList[0].filePath
        // 附件
        var fileList = []
        fileList.push({ fileName: fileName, filePath: filePath })
        var report = {
          projectProcess: projectProcess,
          responsibleUnitName: responsibleUnitName,
          reportCode: reportCode,
          approvalDepartment: approvalDepartment,
          inspectionDate: inspectionDate,
          fileList: fileList,
        }
        this.msEditForm.reportList.push(report)
      } else if (this.reportDialogForm.type === 'edit') {
        let arr = this.msEditForm.reportList
        let report = arr[this.reportDialogForm.rowIndex]
        // 类型
        report.projectProcess = this.reportDialogForm.projectProcess
        // 机构名称
        report.responsibleUnitName = this.reportDialogForm.responsibleUnitName
        // 编号
        report.reportCode = this.reportDialogForm.reportCode
        // 报审部门
        report.approvalDepartment = this.reportDialogForm.approvalDepartment
        //评审时间
        report.inspectionDate = this.reportDialogForm.inspectionDate
        // // 附件
        report.fileList[0].fileName = this.reportDialogForm.annexList[0].fileName
        report.fileList[0].filePath = this.reportDialogForm.annexList[0].filePath
      }
      this.$refs.reportDialogFormRef.show(false)
    },
    // 删除检测报告协议
    deletionPositive(index) {
      let fileName = this.reportDialogForm.annexList[index].fileName
      this.reportDialogForm.annexList.splice(index, 1)
      this.$refs.msPositive.deleteIndex(index)
      let fileList = this.$refs.msPositive.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.msPositive.deleteIndex(j)
            fileList = this.$refs.msPositive.showFileList || []
            continue
          }
        }
      }
    },

    clickError() { },
    mouseenter(index) {
      this.reportDialogForm.annexList[index].check = true
    },
    mouseleave(index) {
      this.reportDialogForm.annexList[index].check = false
    },
    /**
     * 单个文件预览
     */
    openFilePreview(fileName, filePath) {
      let previewFileList = []
      let item = {
        fileName: fileName,
        filePath: filePath,
      }
      previewFileList.push(item)
      this.openFilePreview1(previewFileList, 0)
    },
    /**
     * 多个文件预览
     */
    openFilePreview1(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
    /**
     * 类型格式化
     */
    projectProcessFormatter(row, column, cellValue, index) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        if (cellValue == '2') {
          return '预评价'
        } else if (cellValue == '3') {
          return '防护设计专篇'
        } else if (cellValue == '4') {
          return '控制效果评价'
        }
      } else {
        return ''
      }
    },
    /**
     * 日期格式化
     */
    dateFormatter(row, column, cellValue, index) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        return this.$system.formatDate(cellValue, 'YYYY-MM-DD')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.three-simultaneous-edit-page {
  /deep/ .zwx-cascader-single-common .zwx-input {
    width: 200px !important;
  }
  /deep/ .three-simultaneous-report .el-dialog {
    width: 541px !important;
    min-width: 541px !important;
  }
}
.el-select-dropdown__item.selected {
  color: #409eff;
  font-weight: 100;
}
</style>
<style lang="less">
.el-tooltip__popper {
  background: white !important; /*背景色  !important优先级*/
  color: #0f0101 !important; /*字体颜色*/
  opacity: 1 !important; /*背景色透明度*/
  max-width: 400px;
}
</style>
