var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "three-simultaneous-edit-page" },
    [
      _c(
        "el-form",
        {
          key: "msEditForm",
          ref: "msEditForm",
          staticClass: "zwx-form edit-form",
          attrs: {
            model: _vm.msEditForm,
            rules: _vm.msEditFormRules,
            "label-position": "right"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c("base-headline", {
            staticClass: "no-top",
            attrs: { title: "基本信息" }
          }),
          _c("div", { staticStyle: { "border-bottom": "1px solid #D5D9E0" } }, [
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "85px",
                      label: "项目名称：",
                      prop: "projectName"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "300px !important" },
                      attrs: {
                        placeholder: "请输入",
                        maxlength: "200",
                        clearable: ""
                      },
                      model: {
                        value: _vm.msEditForm.projectName,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.msEditForm,
                            "projectName",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "msEditForm.projectName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { "label-width": "82px", label: "项目地址：" } },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "442px !important" },
                      attrs: {
                        placeholder: "请输入",
                        maxlength: "200",
                        clearable: ""
                      },
                      model: {
                        value: _vm.msEditForm.projectAddr,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.msEditForm,
                            "projectAddr",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "msEditForm.projectAddr"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "82px",
                      label: "项目性质：",
                      prop: "projectNature"
                    }
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "zwx-radio-group",
                        staticStyle: { "min-width": "80px !important" },
                        model: {
                          value: _vm.msEditForm.projectNature,
                          callback: function($$v) {
                            _vm.$set(_vm.msEditForm, "projectNature", $$v)
                          },
                          expression: "msEditForm.projectNature"
                        }
                      },
                      [
                        _c(
                          "el-radio",
                          {
                            staticClass: "zwx-radio",
                            attrs: { label: "40120001" }
                          },
                          [_vm._v("新建")]
                        ),
                        _c(
                          "el-radio",
                          {
                            staticClass: "zwx-radio",
                            attrs: { label: "40120002" }
                          },
                          [_vm._v("改建")]
                        ),
                        _c(
                          "el-radio",
                          {
                            staticClass: "zwx-radio",
                            attrs: { label: "40120003" }
                          },
                          [_vm._v("扩建")]
                        ),
                        _c(
                          "el-radio",
                          {
                            staticClass: "zwx-radio",
                            attrs: { label: "40120004" }
                          },
                          [_vm._v("技术改造")]
                        ),
                        _c(
                          "el-radio",
                          {
                            staticClass: "zwx-radio",
                            attrs: { label: "40120005" }
                          },
                          [_vm._v("技术引进")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "110px",
                      label: "批复单位类型：",
                      prop: "approvedUnitType"
                    }
                  },
                  [
                    _c("cascader-single", {
                      ref: "unitTypeCascader",
                      staticClass: "zwx-cascader-single-common",
                      attrs: {
                        placeholder: "请选择",
                        simpleCodeList:
                          _vm.msEditFormExp.unitTypeSimpleCodeList,
                        expandTrigger: "hover",
                        codeTypeCode: "1003"
                      },
                      model: {
                        value: _vm.msEditForm.approvedUnitType,
                        callback: function($$v) {
                          _vm.$set(_vm.msEditForm, "approvedUnitType", $$v)
                        },
                        expression: "msEditForm.approvedUnitType"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "110px",
                      label: "批复单位级别：",
                      prop: "approvedUnitLevel"
                    }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "zwx-select",
                        attrs: { placeholder: "请选择", clearable: "" },
                        model: {
                          value: _vm.msEditForm.approvedUnitLevel,
                          callback: function($$v) {
                            _vm.$set(_vm.msEditForm, "approvedUnitLevel", $$v)
                          },
                          expression: "msEditForm.approvedUnitLevel"
                        }
                      },
                      _vm._l(_vm.options, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { "label-width": "70px", label: "批复日期：" } },
                  [
                    _c("el-date-picker", {
                      staticClass: "zwx-date-picker",
                      staticStyle: { width: "200px !important" },
                      attrs: {
                        "popper-class": "zwx-date-picker-popper",
                        type: "date",
                        placeholder: "请选择",
                        "picker-options": _vm.$validate.noBigDate(
                          _vm.$data["msEditForm"],
                          "completionDate"
                        )
                      },
                      model: {
                        value: _vm.msEditForm.approvedDate,
                        callback: function($$v) {
                          _vm.$set(_vm.msEditForm, "approvedDate", $$v)
                        },
                        expression: "msEditForm.approvedDate"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { "label-width": "82px", label: "竣工日期：" } },
                  [
                    _c("el-date-picker", {
                      staticClass: "zwx-date-picker",
                      staticStyle: { width: "200px !important" },
                      attrs: {
                        "popper-class": "zwx-date-picker-popper",
                        type: "date",
                        placeholder: "请选择",
                        "picker-options": _vm.$validate.noSmallDate(
                          _vm.$data["msEditForm"],
                          "approvedDate"
                        )
                      },
                      model: {
                        value: _vm.msEditForm.completionDate,
                        callback: function($$v) {
                          _vm.$set(_vm.msEditForm, "completionDate", $$v)
                        },
                        expression: "msEditForm.completionDate"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "160px",
                      label: "职业病危害风险分类：",
                      prop: "hazardRisk"
                    }
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "zwx-radio-group",
                        staticStyle: { "min-width": "80px !important" },
                        model: {
                          value: _vm.msEditForm.hazardRisk,
                          callback: function($$v) {
                            _vm.$set(_vm.msEditForm, "hazardRisk", $$v)
                          },
                          expression: "msEditForm.hazardRisk"
                        }
                      },
                      [
                        _c(
                          "el-radio",
                          { staticClass: "zwx-radio", attrs: { label: 1 } },
                          [_vm._v("严重")]
                        ),
                        _c(
                          "el-radio",
                          { staticClass: "zwx-radio", attrs: { label: 2 } },
                          [_vm._v("一般")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "100px",
                      label: "项目内容：",
                      prop: "projectContent"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-textarea",
                      staticStyle: { width: "1435px !important" },
                      attrs: {
                        type: "textarea",
                        autosize: { minRows: 4 },
                        maxlength: "200",
                        resize: "none",
                        placeholder: "请输入",
                        clearable: ""
                      },
                      model: {
                        value: _vm.msEditForm.projectContent,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.msEditForm,
                            "projectContent",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "msEditForm.projectContent"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "base-headline",
            { staticClass: "no-top no-bottom", attrs: { title: "报告信息" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "zwx-button zwx-button-icontext-28",
                  attrs: { icon: "el-icon-plus" },
                  on: { click: _vm.addReportList }
                },
                [_vm._v("添加")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              key: "reportListTable",
              staticClass: "zwx-table",
              staticStyle: { width: "100%" },
              attrs: {
                "row-key": "rid",
                data: _vm.msEditForm.reportList,
                stripe: "",
                border: "",
                "tooltip-effect": "light"
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "projectProcess",
                  label: "类型",
                  width: "120",
                  "header-align": "center",
                  align: "left",
                  formatter: _vm.projectProcessFormatter
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "responsibleUnitName",
                  label: "报告/专篇编制机构名称",
                  width: "300",
                  "header-align": "center",
                  align: "left",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reportCode",
                  label: "报告专篇编号",
                  width: "200",
                  "header-align": "center",
                  align: "left",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "approvalDepartment",
                  label: "报审部门",
                  width: "200",
                  "header-align": "center",
                  align: "left",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "inspectionDate",
                  label: "评审时间",
                  width: "200",
                  "header-align": "center",
                  align: "center",
                  formatter: _vm.dateFormatter
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "报告附件",
                  "header-align": "center",
                  align: "center",
                  width: "100"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.$zwxBase.verifyIsBlank(scope.row.fileList)
                          ? [
                              _c("div", { staticClass: "zwx-folder-content" }, [
                                _c("i", {
                                  staticClass:
                                    "filled chis-icon-filled-file-folder",
                                  staticStyle: { color: "#1990ff" }
                                }),
                                _c(
                                  "span",
                                  { staticClass: "zwx-folder-content-num" },
                                  [_vm._v("0")]
                                )
                              ])
                            ]
                          : [
                              _c(
                                "el-dropdown",
                                {
                                  attrs: {
                                    placement: "bottom",
                                    trigger: "click"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "zwx-folder-content" },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "filled chis-icon-filled-file-folder",
                                        staticStyle: { color: "#1990ff" }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "zwx-folder-content-num"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(scope.row.fileList.length)
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown"
                                    },
                                    _vm._l(scope.row.fileList, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "el-dropdown-item",
                                        {
                                          key: index,
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.openFilePreview1(
                                                scope.row.fileList,
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(_vm._s(item.fileName))
                                          ])
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-align": "center",
                  align: "left",
                  fixed: "right",
                  "min-width": "100"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.editReport(scope.row, scope.$index)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass:
                              "zwx-button zwx-button-text-26 zwx-button-danger",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.deleteReport(scope.row, scope.$index)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "fixed-footer",
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary", disabled: _vm.saveButtonDisabled },
              on: { click: _vm.saveThreeSimultaneous }
            },
            [_vm._v("保存")]
          )
        ],
        1
      ),
      _c(
        "base-dialog",
        {
          ref: "reportDialogFormRef",
          staticClass: "three-simultaneous-report",
          attrs: {
            title: _vm.reportDialogForm.title,
            pagination: false,
            "if-show-close": true
          },
          on: {
            determine: function($event) {
              return _vm.confirmReportDialogForm()
            },
            cancel: _vm.cancelreportDialogForm,
            close: _vm.closereportDialogForm
          }
        },
        [
          _c(
            "el-form",
            {
              key: "reportFormRef",
              ref: "reportFormRef",
              staticClass: "zwx-form edit-form",
              staticStyle: { padding: "0 !important" },
              attrs: {
                model: _vm.reportDialogForm,
                "label-position": "right",
                rules: _vm.reportRules
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "53px",
                        label: "类型：",
                        prop: "projectProcess"
                      }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "zwx-radio-group",
                          staticStyle: { "min-width": "100px !important" },
                          model: {
                            value: _vm.reportDialogForm.projectProcess,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.reportDialogForm,
                                "projectProcess",
                                $$v
                              )
                            },
                            expression: "reportDialogForm.projectProcess"
                          }
                        },
                        [
                          _c(
                            "el-radio",
                            { staticClass: "zwx-radio", attrs: { label: 2 } },
                            [_vm._v("预评价")]
                          ),
                          _c(
                            "el-radio",
                            { staticClass: "zwx-radio", attrs: { label: 3 } },
                            [_vm._v("防护设计专篇")]
                          ),
                          _c(
                            "el-radio",
                            { staticClass: "zwx-radio", attrs: { label: 4 } },
                            [_vm._v("控制效果评价")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "170px",
                        label: "报告/专篇编制机构名称：",
                        prop: "responsibleUnitName"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        staticStyle: { width: "320px !important" },
                        attrs: {
                          placeholder: "",
                          maxlength: "200",
                          clearable: ""
                        },
                        model: {
                          value: _vm.reportDialogForm.responsibleUnitName,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.reportDialogForm,
                              "responsibleUnitName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "reportDialogForm.responsibleUnitName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "115px",
                        label: "报告/专篇编号：",
                        prop: "reportCode"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        staticStyle: { width: "374px !important" },
                        attrs: {
                          placeholder: "",
                          maxlength: "50",
                          clearable: ""
                        },
                        model: {
                          value: _vm.reportDialogForm.reportCode,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.reportDialogForm,
                              "reportCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "reportDialogForm.reportCode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "81px",
                        label: "报审部门：",
                        prop: "approvalDepartment"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        staticStyle: { width: "147px !important" },
                        attrs: {
                          placeholder: "",
                          maxlength: "200",
                          clearable: ""
                        },
                        model: {
                          value: _vm.reportDialogForm.approvalDepartment,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.reportDialogForm,
                              "approvalDepartment",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "reportDialogForm.approvalDepartment"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "81px",
                        label: "评审日期：",
                        prop: "inspectionDate"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        staticClass: "zwx-date-picker",
                        staticStyle: { width: "160px !important" },
                        attrs: {
                          "popper-class": "zwx-date-picker-popper",
                          type: "date",
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd",
                          placeholder: "",
                          "picker-options": _vm.pickerOptions
                        },
                        model: {
                          value: _vm.reportDialogForm.inspectionDate,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.reportDialogForm,
                              "inspectionDate",
                              $$v
                            )
                          },
                          expression: "reportDialogForm.inspectionDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "81px",
                        label: "报告附件：",
                        prop: "annexList"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "file-upload",
                            {
                              ref: "msPositive",
                              attrs: {
                                accept: ".pdf,.PDF",
                                size: 50 * 1024 * 1024,
                                sizeInfo: "50M"
                              },
                              on: {
                                showFileList: _vm.clickPositive,
                                success: _vm.clickPositiveSuccess,
                                error: function($event) {
                                  return _vm.clickError()
                                }
                              }
                            },
                            [
                              _vm.$zwxBase.verifyIsBlank(
                                _vm.reportDialogForm.annexList
                              ) || _vm.reportDialogForm.annexList.length < 1
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "left" } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass:
                                            "zwx-button zwx-button-icontext-28",
                                          attrs: { icon: "el-icon-upload2" }
                                        },
                                        [_vm._v("上传")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm.reportDialogForm.annexList.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "show-file",
                                  staticStyle: { display: "inline-block" }
                                },
                                _vm._l(_vm.reportDialogForm.annexList, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.fileName,
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center"
                                      },
                                      on: {
                                        mouseenter: function($event) {
                                          return _vm.mouseenter(index)
                                        },
                                        mouseleave: function($event) {
                                          return _vm.mouseleave(index)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "file-name-hidden" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticStyle: {
                                                color: "#194DFF !important",
                                                "margin-left": "0px",
                                                cursor: "pointer"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openFilePreview1(
                                                    _vm.reportDialogForm
                                                      .annexList,
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                class: item.fileIconShow,
                                                staticStyle: {
                                                  color: "#316CF5!important"
                                                }
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    title: item.fileName
                                                  }
                                                },
                                                [_vm._v(_vm._s(item.fileName))]
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c("div", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "el-upload-list__item check",
                                            style: item.check
                                              ? "margin-left:18px;display:none;"
                                              : "margin-left:18px;cursor:pointer"
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-upload-success el-icon-circle-check"
                                            })
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "check",
                                            style: item.check
                                              ? "margin-left:18px;cursor:pointer"
                                              : "margin-left:18px;display:none;"
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-close",
                                              on: {
                                                click: function($event) {
                                                  return _vm.deletionPositive(
                                                    index
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }